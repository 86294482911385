<template>
  <div class="flex flex-col justify-start items-center font-Inter mx-8 mt-6">
    <div class="bg-white w-1/2 p-8">
      <h2 class="mb-3 text-lg font-medium">Modification profile</h2>

      <el-form class="form" ref="userForm" :rules="rules" :model="userForm">
        <el-form-item label="Nom et Prénom " prop="full_name">
          <el-input
            prefix-icon="el-icon-user"
            type="text"
            v-model="userForm.full_name"
          />
        </el-form-item>
        <el-form-item label="Email " prop="email">
          <el-input
            prefix-icon="el-icon-message"
            type="email"
            v-model="userForm.email"
          />
        </el-form-item>
        <el-form-item label="Téléphone " prop="phone">
          <el-input
            prefix-icon="el-icon-mobile"
            type="phone"
            v-model="userForm.phone"
          />
        </el-form-item>
      </el-form>
      <div class="flex justify-between">
        <el-button type="text" @click="dialogVisible = true"
          >Modifier le mot de passe</el-button
        >
        <el-button
          type="primary"
          :loading="loading"
          :disabled="loading"
          @click="updateData('userForm')"
          >Enregistrer</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { updateUser } from "@/api/user";
import store from "../../store";

export default {
  name: "USER",
  data() {
    return {
      loading: false,
      username: "",
      userForm: {
        full_name: "",
        email: "",
        phone: "",
      },

      rules: {
        full_name: [
          {
            required: true,
            message: "Le nom est obligatoire",
            trigger: "change",
          },
        ],
        email: [
          {
            required: true,
            message: "Email est obligatoire",
            trigger: "change",
          },
        ],
        phone: [
          {
            required: true,
            message: "Téléphone est obligatoire",
            trigger: "change",
          },
        ],
      },
    };
  },

  mounted() {
    this.getInfos();
  },
  methods: {
    async getInfos() {
      const { user } = await store.dispatch("user/getInfo");
      this.userForm.full_name = user.name;
      this.userForm.email = user.email;
      this.userForm.phone = user.phone;
      this.username = user.email;
    },
    async updateData(userForm) {
      this.$refs[userForm].validate((valid) => {
        if (valid) {
          this.loading = true;
          updateUser(this.userForm, this.username)
            .then(() => {
              setTimeout(() => {
                this.loading = false;
                this.$message({
                  title: "Success",
                  message: "Modification effectuée",
                  type: "success",
                  duration: 2000,
                });
              }, 1.5 * 1000);
            })
            .catch((error) => {
              setTimeout(() => {
                this.loading = false;
                this.$message({
                  title: "error",
                  message: error.data.message,
                  type: "error",
                  duration: 2000,
                });
              }, 1.5 * 1000);
            });
        }
      });
    },
  },
};
</script>

<style></style>
